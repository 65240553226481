import React, { Fragment } from 'react';
import SEO from '../components/seo';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Portfolio/portfolio.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';
import Container from 'common/src/components/UI/Container';
import { MainWrapper } from '../containers/LetsTalk/Calendar/calendar.style';
import Box from 'reusecore/src/elements/Box';

const PrivacyPolicy = ({
	row,
	col,
}) => {
	return (
		<ThemeProvider theme={portfolioTheme}>
			<Fragment>
				<SEO title="Privacy Policy | Mario Markovic" />
				<ResetCSS />
				<GlobalStyle />

				<ContentWrapper>
					<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
						<DrawerProvider>
							<Navbar className="nav-static" />
						</DrawerProvider>
					</Sticky>
					<MainWrapper>
						<Container noGutter mobileGutter width="1200px">
							<Box {...row}>
								<Box {...col} className="text-left">
								<h1>Website Privacy Policy</h1>
								<p>At MarioMarkovic.com (“Website”), I collect and manage user data according to the following Privacy Policy.</p>
								<h2>Data Collected</h2>
								<p>I collect information you provide directly to me. For example, I collect information when you fill out a form, send me a direct email or otherwise communicate with me. The types of information I may collect include your name, email address, postal address, country and other contact or identifying information you choose to provide.</p>
								<p>I collect anonymous data from every visitor of the Website to monitor traffic and fix bugs. For example, I collect information like web requests, the data sent in response to such requests, the Internet Protocol address, browser type, the browser language, and a timestamp for the request.</p>
								<p>I also use various technologies to collect information, and this may include sending cookies to your computer. Cookies are small data files stored on your hard drive or in your device memory that helps me to improve my services and your experience, see which areas and features of my services are popular and count visits. I may also collect information using web beacons (also known as "tracking pixels"). Web beacons are electronic images that may be used in our services or emails and to track count visits or understand usage and campaign effectiveness.</p>
								<h2>Use of the Data</h2>
								<p>I only use your personal information to provide you the MarioMarkovic.com services or to communicate with you about the Website or the services.</p>
								<p>I employ industry standard techniques to protect against unauthorized access of data about you that I store, including personal information.</p>
								<p>I do not share personal information you have provided to me without your consent, unless:</p>
								<p>- Doing so is appropriate to carry out your own request
									<br />- I believe it's needed to enforce my legal agreements or that is legally required
									<br />- I believe it's needed to detect, prevent or address fraud, security or technical issues</p>
								<h2>Sharing of Data</h2>
								<p>I don't share your personal information with third parties. Aggregated, anonymized data is periodically transmitted to external services to help me improve the Website and service.</p>
								<p>I may allow third parties to provide analytics services. These third parties may use cookies, web beacons and other technologies to collect information about your use of the services and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information.</p>
								<p>I also use social buttons provided by services like Twitter, Google+, LinkedIn and Facebook. Your use of these third party services is entirely optional. I’am not responsible for the privacy policies and/or practices of these third party services, and you are responsible for reading and understanding those third party services' privacy policies.</p>
								<h2>Opt-Out, Communication Preferences</h2>
								<p>You may modify your communication preferences and/or opt-out from specific communications at any time. Please specify and adjust your preferences.</p>
								<h2>Security</h2>
								<p>I take reasonable steps to protect personally identifiable information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. But, you should keep in mind that no Internet transmission is ever completely secure or error-free. In particular, email sent to or from the Sites may not be secure.</p>
								<h2>About Children</h2>
								<p>The Website is not intended for children under the age of 13. I do not knowingly collect personally identifiable information via the Website from visitors in this age group.</p>
								<h2>Changes to the Privacy Policy</h2>
								<p>I may amend this Privacy Policy from time to time. Use of information I collect now is subject to the Privacy Policy in effect at the time such information is used.</p>
								<p>If I make major changes in the way I collect or use information, I will notify you by posting an announcement on the Website or sending you an email.</p>
								</Box>
							</Box>
						</Container>
					</MainWrapper>
					<Footer />
				</ContentWrapper>
			</Fragment>
		</ThemeProvider>
	);
};

PrivacyPolicy.propTypes = {
	row: PropTypes.object,
	col: PropTypes.object,
  };
  
  PrivacyPolicy.defaultProps = {
	row: {
	  flexBox: true,
	  flexWrap: 'wrap',
	  ml: ['-15px', '-15px', '-15px', '-25px', '-25px'],
	  mr: ['-15px', '-15px', '-15px', '-25px', '-25px'],
	},
	col: {
	  width: ['100%', '100%', '100%', '60%', '50%'],
	  pl: ['15px', '15px', '15px', '25px', '25px'],
	  pr: ['15px', '15px', '15px', '25px', '25px'],
	  textAlign: 'left'
	},
  };

export default PrivacyPolicy;
