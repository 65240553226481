import styled from 'styled-components';

export const MainWrapper = styled.main`
    text-align: center;
    padding-top: 180px;
    padding-bottom: 100px;

    @media (max-width: 689px) {
        padding-top: 120px;
    }

    p {
        line-height: 1.6;

        @media (max-width: 575px) {
            font-size: 14px;
        }
    }

    .text-left {
        text-align: left;
    }
`

export const CalendarWrapper = styled.div`
    max-width: 1070px;
    margin: 0px auto;
    margin-bottom: 40px;
    height: 1050px;

    @media (min-width: 576px) {
        padding: 0 20px;  
        height: 900px;      
    }

    iframe {
        width: 100%;
        height: 100%;

        @media (max-width: 689px) {
            border-radius: 8px;
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
        }
    }
`

export const BackToHomeWrapper = styled.div`
    a {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
`